// Default Styles
// Description: Set all default general styles and classes here

html, body {
	min-height: 100%;
	height: 100%;
}

a {
	color: $base;
	text-decoration: none !important;
	@include transition();
	&:hover,&:focus {
		color: $highlight;
	}
}


html {
	font-size: 62.5%
}
body {
	font-family: $font-primary;
	font-weight: 300;
	@include font-size(1.4);
	-webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-size-adjust:none;

}

.clear {
	&:after,
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
	*zoom: 1;
}

.hide-text {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

img {
	border: none;
	&.responsive {
		max-width: 100%;
		height: auto;
	}
}

.left {
	float: left;
}

.right {
	float: right;
}

table {
	td {
		vertical-align: top;
	}
	width: 100%;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.visible {
	overflow: visible;
}
