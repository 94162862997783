// Viewport Primary Styles
.wrapper {
	padding-left: 15px;
	padding-right: 15px;
	@media (min-width: 768px) {
		min-width: $screen-tablet;
	}
}
#viewport {
	&.wrapper {
		padding-left: 10px;
		padding-right: 10px;
	}
	h4 {
		margin-top: 25px;
	}
	.row {
		margin-bottom: 20px;
		.row {
			margin-top: 10px;
			margin-bottom: 0;
		}
	}
	[class*="col-"] {
		padding-top: 15px;
		padding-bottom: 15px;
		background-color: #eee;
		border: 1px solid #ddd;
		background-color: rgba(86,61,124,.15);
		border: 1px solid rgba(86,61,124,.2);
	}
	hr {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}
