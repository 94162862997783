// Application Style Controller
// Description: Set Imports and Variables (Image) Here

// Paths Relative to /app/_/css
// ------------------------------------------------------------------------

$img-url: "../img";

// Example of how to use -- background: url("#{$img-url}/background-image.png") repeat top left;

// Include Libaries
@import "libraries";

// Color Palette & Mixins for the Application
// ------------------------------------------------------------------------
@import "globals/colors";
@import "globals/custom-mixins";
@import "globals/fonts";

// Globals
// ------------------------------------------------------------------------
@import "globals/defaults";

// Start Application Styles & Settings
// ------------------------------------------------------------------------
@import "partials/viewport";

//Import style for select2 widgets
@import "partials/select2";

//Import style for typeahead
@import "../../responsive/scss/modules/typeahead";
@import "partials/typeahead";

// Add additional /partials Below
// ------------------------------------------------------------------------
// @import "partials/...";

// Mobile Specific Styles & Settings
@import "globals/mobile";

// Import modules
@import "modules/datepicker";
$colorInfo        : #2098cc;
@import "../../responsive/scss/modules/chatlio";

@import "../../bower_components/jquery-ui/themes/base/base.css";
@import "../../bower_components/jquery-ui/themes/base/theme.css";
@import "../../bower_components/dropzone/dist/dropzone.css";
@import "../../css/select2.css";

@import "../../responsive/scss/modules/load_indicator";
// ------------------------------------------------------------------------
//COLORS
// ------------------------------------------------------------------------

.dashboard article img {
	width: 100%;
	max-width: 100%;
}

.sugar-blu{
	color: $sugar-blu !important;
}
.link-blu{
	color: $light-blu !important;
	text-decoration: underline !important;
	&:hover{
		text-decoration: none !important;
		color: darken($light-blu, 30%);
	}
}
.row.profile{
	background-color: $profile-blu;
	padding: 15px 0;
}
.progress{
	background-color: $dark-blu;
	.progress-bar{
		background-color: $branding-red;
		color: $white;
	}
}
#dashbar footer p{
	padding-top: 30px;
	color: $sugar-blu;

	a{
		color: $sugar-blu;
	}
}
.back{
	background-color: grey !important;
	border-color: grey !important;
	&:hover{
		background-color: #3071a9 !important;
		border-color: #285e8e !important;
	}
}
.alert{
	position: relative;
	.closing{
		position: absolute;
		right: 0;
		top: 5px;
		background: transparent;
		border: 0;
	}
}
.alert-warning{
	background-color: $alert-warning;
	color: $alert-warning-text;
}

.bg-info{
	position: relative;
	p > a{
		text-decoration: underline !important;
	}
	p{
		margin: 0;
	}
	.closing {
		position: absolute;
		right: 10px;
		top: 5px;
		background: transparent;
		border: 0;
	}
}
.btn-primary{
	background-color: $light-blu;
}
.btn-loading{
	@include transparent();
}


.booking{
	background-color: #2489e7 !important;
	color: $white;
}
.option{
	background-color: #189176 !important;
	color: $white;
}
.manteinance{
	background-color: #e7bb24 !important;
	color: $white;
}
.discount{
	background-color: #e72449 !important;
	color: $white;
}
.available{
	background-color: #26a329 !important;
	color: $white;
}
.helper-desciption {
	@extend .help-block;
	font-size: 100% !important;
}
body p {
	color: $dark-grey;
	position: relative;
}
.badge {
	background-color: $tag-booked;	font-weight: 300;
	padding: 5px 7px;
	margin-left: 5px !important;
}
.profile {
	h4{
		font-weight: 300;
		margin: 5px 0;
	}
}
.profile-buttons{
	.btn-group-justified{
		border-bottom: 2px solid $profile-blu;
		width: 99%;
	}
	span {
		margin-right: 5px;
	}
	.btn-default{
		background: transparent;
		border: none;

		&:first-child{
			border-right: 2px solid $profile-blu
		}
		&:first-child:hover{
			border-right: 2px solid $profile-blu
		}

		&:hover{
			background: $dark-blu;
			border: none;
			color: $white !important;
		}
		&.active{
			background: $branding-red;
			border: none;
			color:  $white !important;
		}


	}
}

.progress{
	height: 25px;
	.progress-bar{
		line-height: 26px;
	}
}

#dashbar {
	h5{
		font-weight: 300;
		margin: 24px 0 12px 0;
	}
}

#dashNav{
	a{

		@include font-size(1.6);
		font-weight: 300;

		small{
			color: $sugar-blu;
			@include font-size(1.3);

			padding-left: 15px;
		}

		.glyphicon, .sailogy, .icomoon {
			padding-right: 15px;
		}
	}
}
.fa{
	padding-right: 10px;
}
h3.nav-pills-title {
	padding: 0 15px;
	color: $sugar-blu;
	@include font-size(1.7);
	font-weight: 300;
}

// * MAIN SECTION *//

#main{
	padding-bottom: 30px;
	h1{
		@include font-size(2.2);
		font-weight: 300;
		margin: 30px 0;
	}

	h2{
		@include font-size(1.7);
		font-weight: 300;
		line-height: 24px;
		margin-top: 0;
		margin-bottom: 15px;
	}
}

.messages{
	dd{

		@include font-size(1.3);
	}
	dt{
		margin: 15px 0 5px 0;
	}
}

span.icon-prev{
	margin-right: 5px;
}
span.icon-next{
	margin-left: 5px;
}

// * WIZARD PAGES * //

#wizard{
	background-color: $extra-light-grey;
}
.steps{
	z-index: 1030;
	width: 100%;/*$screen-lg;*/
	margin-top: -2px;
	position: fixed;
}
.wizard{
	padding-bottom: 15px;
	padding-top: 100px;
}

.row.spoken-languages{
	h3{
		@include font-size(1.7);
		font-weight: 300;
		margin-top: 0;
	}
}
.checkbox{
	margin-bottom: 20px;
	label{
		@include font-size(1.5);
		font-weight: 300;
	}
}

.wizard h3 {
	display: inline-block;
	padding-left: 20px;
	color: $dark-grey;
	@include font-size(1.8);
	font-weight: 300;
}

.wizard .empty_form {
	display: none;
}

.wizard #boat-form {

	#specs .container {
		width: 75%;
		max-width: 600px;

		.boat-shape {
			background-position: 55% 10px;
		}
	}

	.equipments {

		h3 {
			padding-left: 0;
			span {
				padding-left: 10px;
				padding-right: 5px;
			}
		}

		&>div {
			@include clearfix;
		}

		.glyphicon {
			color: $dark-grey;
		}
	}
}
#wizard-pricelist{
	th, td{
		text-align: center;
		vertical-align: middle;
		padding: 20px 15px;
		min-width: 160px;

		h3{
			font-size: 15px;
			@include font-size(1.5);
			font-weight: bold;
			margin: 5px 0;
		}
		span{
			color: $dark-grey;
			font-weight: 300;
		}
		h4{
			margin-bottom: 5px;
		}
	}
	td:first-child{
		text-align: left;
	}
	th:first-child{
		small{
			@include font-size(1.3);
			font-weight: 400;
			color: $dark-grey;
		}
	}
}

#pricelist-extras-wizard-form {
	.extra-row.removed input,
	.extra-row.removed label,
	.extra-row.removed div,
	.extra-row.removed select{
		opacity: 0.5;
	}
	.extra-row.removed .extra-restore {
		display: block;
	}
	.extra-row.removed .unmark_form, .extra-row .extra-restore {
		display: none;
	}
	// .extra-restore {
	// 	position: absolute;
	// 	right: 0;
	// 	bottom: 35px;
	// }
}

ul#negotiations-details{
	li{
		color: $black;
		@include font-size(1.5);
		font-weight: 400;

		h4{
			font-weight: 300;
			color: $dark-grey;
			@include font-size(1.3);
		}
	}
}


.thanks {
	min-height: 600px;
	position: relative;
	padding-top: 30px;
}

.thanks h2 {
	@include font-size(2.2);
}

.thanks p {
	margin-top: 30px;
	@include font-size(1.5);
}

.thanks .downbelow {
	position: absolute;
	bottom: 30px;
	margin: 0 auto;
	left: 0;
	right: 0;
	a {
		text-decoration: underline !important;
	}
}

.thanks .btn {
	@include font-size(1.8);
	background-color: $branding-red;
	border-color: $branding-red;
	color: $white;
	&:hover{
		color: $white;
		background-color: $light-blu;
		border-color: #357ebd;
	}
	padding-left: 20px;
	padding-right: 20px;
	width: 280px;
	span {
		padding-left: 10px;
	}
}

.bigfooter {
	padding: 30px;
	background-color: $dark-blu;
	color: $white;
	border-top:5px solid $blu;
	h3 {
		@include font-size(2);
		margin-bottom: 30px;
	}

	p {
		color: #909090;
	}

	a {
		color: #909090;
		&:hover {
			color: $light-blu;
		}
	}
	li {
		margin-bottom: 5px;
	}
	.microdata {
		color: #909090;
		div {
			margin-bottom: 5px;
		}
	}
	footer {
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid $dark-grey;
	}
}
.footdash {
	padding: 15px;
	background-color: #fff;
	h3 {
		color: #909090
	}
	border-color: transparent;
	footer {
		border-color: silver;
	}
}
html, body {
	height: 100%;
}
body {
	padding-top: 66px;
	background-color: #ececec;
	> footer {
		background: white;
	}
}
.starter-template {
	padding: 40px 15px;
	text-align: center;
}
textarea {
	max-width: 100%;
}
// * TOP NAVIGATION * //
.navbar-inverse {
	background-color: $white;
	height: 65px;
	max-height: 65px;
	border-bottom: 0;
}
.navbar-header {
		h2{
			margin: 15px 0;
			a {
				padding: 0;
				background: url(../img/logo.jpg);
				width: 131px;
				height: 34px;
				margin: 0;
			}
			small{
				font-family: $font-secondary;
				@include font-size(2.0);
				line-height: 2em;
				padding-left: 15px;
			}
		}
		.nav-justified{
			height: 65px;
			>li {
				width: 22%;
				float: left;
				margin: 0;
				padding: 0;
				&:first-child a{
					border-left: 1px solid $light-grey;
				}
				&.active > a {
					background-color: $blu;
				}
			}
			a{
				background: none;
				padding: 15px 0;
				height: 65px;
				border-right: 1px solid $light-grey;
				border-radius: 0;
				font-weight: 400;

				&:hover{
					background-color: #f9f9f9;
					color: $black;
				}

				span.glyphicon, span.sailogy{
					@include font-size(2.4);
					position:relative;
					top: inherit;
					bottom: -5px;
					left: -5px;
					line-height: inherit;
				}
			}
		}
	}

	.navbar-header .fa {
		margin-right: 5px!important;
		top: 10px;
	}

	.btn-default, .btn-primary{
		padding: 6px 20px;
	}

	.btn-default{
		color: $light-blu;
		border-color: $light-blu;
		&:hover{
			background-color: $light-blu;
			color: $white;
			border: 1px solid $light-blu;
		}
	}
	.no-margin{
		margin: 0;
	}

	.btn-primary:active {
		background-color: darken($light-blu, 30%);
		border-color: darken($light-blu, 30%);
		color: $white;
		outline: 0;
	}
// ------------------------------------------------------------------------
// DASHBOARD LAYOUT
// ------------------------------------------------------------------------

// * DASHBOARD NAVIGATION *//

#dashbar{
	background-color: $blu;
	color: $white;
	padding-bottom: 30px;
	position: fixed;
	bottom: 0;
	left: 0;
	top: 65px;
	width: 250px;

	.profile {
		overflow: hidden;
		padding: 15px 0;
	}
}

#profile-pic {
	width: 70px;
	float: left;
}
#profile-pic img{
	width: 100%;
	height: 70px;
}

#profile-info {
	float: left;
	clear: right;
	padding-left: 15px;
	width: 150px;
}

#dashNav{
	/*margin-top: 30px;*/
	li{
		a{
			background-color: none;
			border-radius: 0;
			color: $white;
			&:hover, &:focus{
				background-color: $dark-blu;
			}
		}
	}
	li.active a{
		background-color: $dark-blu;
		border-left: 5px solid $branding-red;
	}
}


.nav-tabs {


	&>li {

		&>a {
			background-color: $tabs-li-background;
			padding: 5px 10px;
			@include font-size(1.65);
			font-weight: 300;
			border: 0;
			&.active {
			}
		}

		// Active state, and its :hover to override normal :hover
		&.active > a {
			&,
			&:hover,
			&:focus {
				color: $nav-tabs-active-link-hover-color;
				background-color: $nav-tabs-active-link-hover-bg;
				border: 1px solid $nav-tabs-active-link-hover-border-color;
			}
		}
	}
}




// * MAIN SECTION *//

.half-padding-right{
	padding-right: 7.5px;
}
.half-padding-left{
	padding-left: 7.5px;
}

#main div.row section article{
	background-color: $white;
	padding: 15px;
	margin-bottom: 15px;
	@include border-radius(2px, 2px, 2px, 2px);
	@include box-shadow($light-grey 2px 2px 2px );
}

.profile-complete article{
	border-top: 3px solid $orange;
}
/*textarea{
	margin-bottom: 15px;
}*/

.bar-legend span {
	height: 16px;
	width: 16px;
	float: left;
}

.profile-complete .btn-default{
	border: none;

	&:hover{
		background-color: transparent;
		color: $branding-red !important;
	}
}

.label-default {
	background-color: transparent;
	padding-left: 0;
}
.label-default.optioned{
	color: $tag-optioned;
	background-color: transparent !important;
}

.label-default.available{
	color: $tag-available;
	background-color: transparent !important;
}

.label-default.booked{
	color: $tag-booked;
	background-color: transparent !important;
}

.messages{
	dt{
		position: relative;
		small{
			position: absolute;
			right: 0;
			color: $dark-grey;
		}
	}
}


// * FIRST TIME IN DASHBOARD *//

.wish {
	button {
		margin-top: 5px;
	}
}

a#boat-preview{
	display: block;
	margin: 0 auto;
	padding-top: 140px;
	width: 70%;
	background: url(../img/boat-preview.jpg) no-repeat 50% 0;
	/*background-size: contain;*/
	text-align: center;
	color: $light-blu;

	&:hover{
		color: $branding-red;
	}
}

a#goto-calendar{
	display: block;
	margin: 0 auto;
	padding-top: 140px;
	width: 70%;
	background: url(../img/calendar.jpg) no-repeat 50% 0;
	/*background-size: contain;*/
	text-align: center;
	color: $light-blu;
	&:hover{
		color: $branding-red;
	}
}

// * RIBBON STYLING* //

.z-index{
	position: relative;
	z-index: 1;
}
.ribbon {
	color: $white;
	position: relative;
	background: #3d6c99;
	width: 90%;
	text-align: center;
	padding: 5px;
	margin: 40px auto 0 auto !important;

	strong{
		font-family: $font-secondary;
		text-shadow: 1px 2px $blu;
		color: $white;
	}

	&:before, &:after{
		content: "";
		position: absolute;
		display: block;
		bottom: -1em;
		border-left: 1em solid #3d6c99;
		border-top: 1.5em solid #3d6c99;
		border-bottom: 1.5em solid #3d6c99;
		border-right: 1em solid #3d6c99;
		z-index: -1;
	}

	.ribbon-content:before, .ribbon-content:after {
		content: "";
		position: absolute;
		display: block;
		border-style: solid;
		border-color: #1a3b5a transparent transparent transparent;
		bottom: -1em;
	}

}

.ribbon:before {
	left: -1em;

}
.ribbon:after {
	right: -1em;

}

.ribbon .ribbon-content:before {
	left: 0;
	border-width: 1em 0 0 1em;
}
.ribbon .ribbon-content:after {
	right: 0;
	border-width: 1em 1em 0 0;
}

// * END RIBBON STYLING * //

.alert-warning{
	a{
		text-decoration: underline !important;
		color: $alert-warning-text;
		&:hover{
			color: #000;
		}
	}
}

// * FOOTER * //


.dashboard footer, .negotiations footer, .negotiations-details footer, .landing footer, .profile footer{
		/*position: absolute;*/
		bottom: 0;
		p{
			padding-top: 30px;
			@include font-size(1.2);
		}
	}

// ------------------------------------------------------------------------
// DASHBOARD GENERIC TABS STYLING
// ------------------------------------------------------------------------


.dashboard .sailogy{
	margin-right:10px;
}

.dashboard .alert{
	margin: 0 15px 15px  0;
	padding: 5px 10px;
}

.dashboard .bg-info{
	margin-bottom: 15px;
	padding: 5px 10px;
}

.dashboard .text-info a {
	text-decoration: underline !important;
}

.dashboard form {
	padding: 0 15px 0 15px;
	.fieldgroup {
		background: $extra-light-grey;
		margin-bottom: 0;
	}

}

.dashboard footer{
	margin: 0 !important;

	.form-group{
		display: block;
	}

	span.glyphicon{
		margin-right: 5px;
		@include font-size(1.5);
	}
}
.dashboard .tab-pane form{
	/*background-color: $white;*/
	@include clearfix;
}

.dashboard .tab-pane > div {
	padding: 0;
	/*padding-top: 30px;*/
	&.fieldgroup {
		padding-top: 30px;
	}
}

.dashboard #main {
	margin-left: 250px;

	form > h2{
		margin: 30px;
		display: block;
		padding: 15px 0;
		border-bottom: 1px solid $silver;
		@include font-size(2.1);
	}
}

.dashboard .form-group{
	.switch label {
		@include font-size(1.5);
	}
	label{
		@include font-size(1.8);
	}
	input[type="text"],
	input[type="password"],
	input[type="number"],
	input[type="url"],
	select,
	textarea{
			/*background-color: $extra-light-grey;*/
			&:focus {
				background-color: $white;
			}
		}
		h2 {
			@include font-size(1.8);
			font-size: 18px!important;
			margin-bottom: 5px!important;
		}
	}
	.dashboard footer .form-group{
		min-height: 0;
		padding-top: 15px;
	}


	.dashboard .meter {
		border-radius: 10px;
		margin-bottom: 30px;
		position: relative;
		div {
			padding: 10px 0;
			color: $white;
		}
		.meter-status {
			position: absolute;
			width: 85px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			background: $profile-blu;
			z-index: 999;
			color: $darker-grey;
			@include font-size(1.3);
			padding-top: 5px;
			padding-bottom: 5px;
			bottom: -4px;
		left: 150px; //THIS ELEMENT SHOULD BE DYNAMIC //
		span {
			width: 100%;
			display: block;
			@include font-size(1.5);
		}
		.arrow-down {
			position: absolute;
			top: 25px;
			left: 32px;
		}
	}

	.online {
		background-color: $green;
	}
	.not-online {
		background-color: $branding-red;
		-webkit-border-top-left-radius: 4px;
		-webkit-border-bottom-left-radius: 4px;
		-moz-border-radius-topleft: 4px;
		-moz-border-radius-bottomleft: 4px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	.the-best {
		background-color: $light-blu;
		-webkit-border-top-right-radius: 4px;
		-webkit-border-bottom-right-radius: 4px;
		-moz-border-radius-topright: 4px;
		-moz-border-radius-bottomright: 4px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}
// * DASHBOARD MARINA * //

a#add_new{
	background-color: $light-blu;
	color: $white;
	&:hover{
		background-color: #3071a9;
	}
}
#main .dashboard-marina{
	background: $white;
	padding-top: 30px;

	h2{
		@include font-size(1.8);
		margin-bottom: 5px;
		display: block;
	}

	fieldset > h2{
		padding: 0 15px;
	}
	.form-group{
		margin-bottom: 10px;
	}
	.checkbox label{
		padding-left: 30px;
	}

	.alert{
		@include clearfix;
		padding: 0;
	}

	.checkbox-inline input[type="checkbox"]{
		margin-left: -25px;
		background-color: #ececec;
		background: #ececec;
	}

	#drag{
		margin-top: 35px;
	}
}

#base-info-actions {
	margin-top: 20px;
	margin-left: 20px;
}

button.remove{
	background-color: $branding-red;
	border-color: $branding-red;
	color: $white;
	&:hover{
		color: $white;
		background-color: $light-blu;
		border-color: #357ebd;
	}
}


// * DASHBOARD boat * //

.dashboard {
	#boat-form {
		#specs .container {
			width: 100%;
			padding: 0;
			.length {
				margin-top: 95px;
			}
		}

		fieldset.equipments {
			margin-left: 30px;
			margin-right: 30px;
			&>div {
				@include clearfix;
			}
			h3 {
				@include font-size(1.8);
			}
		}
	}
	fieldset.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.dashboard .boat-pictures {
	/* The total progress gets shown by event listeners */

	#actions {
		margin-top: 20px;
		margin-left: 20px;

		#total-progress {
			opacity: 0;
			transition: opacity 0.3s linear;
			height: 30px;
		}
	}

	.img-boat {
		button {
			left: 0;
			top: -15px;
			position: absolute;
			padding: 5px;
		}

		/* Hide the delete button initially */
		/*.delete {
		  display: none;
		}*/

		&.dz-processing {
			cursor: progress;
			.delete {
				display: none;
			}

		}

		&.dz-success, &.dz-complete {
			cursor: move;
			/* Hide the progress bar when finished */
			.cancel, .progress, .size, .name {
			  /*opacity: 0;
			  transition: opacity 0.3s linear;*/
			  display: none;
			}

			/* Show remove button when finished */
			.delete {
				display: block;
			}
		}

		/*.progress {
			position: absolute;
			left: 20%;
			right: 20%;
			bottom: 40%;
			height: 10px;
		}*/
		.preview.checked img {
			@include transparent();
		}
	}
}

.dashboard #extras{
	footer{
		.inner-footer{
			border-top: 0;
		}
	}
}

.dasboard-boat-extras{

	.text-info{
		padding: 0 15px;
	}
	fieldset{
		border-top: 1px solid $darker-grey;
		margin: 15px;
		padding-top: 30px;
		position: relative;
	}

	h2{
		padding: 0;
	}

	.form-group{
		min-height: 45px;
		padding: 0 15px;
		margin-bottom: 10px;

		label{
			@include font-size(1.5);
			color: #476e9a;
			font-weight: 300;
		}
	}
	label.checkbox-inline{
		margin: 0 5px 0 0;
	}

	.input-group-addon{
		background: transparent;
		border: none;
	}

	.input-group{
		float: left;
		width: 30%; margin-right: 5px;

		input{
			border-bottom-left-radius: 4px !important;
			border-top-left-radius: 4px !important;
		}
	}
	.parent-select 	select{
		float: left;
		width: 65%;
	}

	.active-switch input.cmn-toggle-round + label:before {
		content: "Disabled";
		padding: 5px 40px;
	}

	.active-switch input.cmn-toggle-round:checked + label:before {
		content: "Active";
	}
	.active-switch input.cmn-toggle-round+label{
		width: 150px;
	}
	.active-switch input.cmn-toggle-round:checked+label:after{
		margin-left: 110px;
	}

	.close{
		position: absolute;
		bottom: auto;
		top: 10px;
		right: 10px;
		opacity: 1;
	}

}

#discounts{

	> .row{
		padding: 15px;
	}
	table caption{
		text-align:left;
		margin-bottom: 15px;
	}

	table .form-group{
		margin-bottom: 0;
	}
	.amount{
		max-width: 150px;
	}

	.active-switch input.cmn-toggle-round + label:before {
		content: "Disabled";
		padding: 5px 35px;
	}

	.active-switch input.cmn-toggle-round:checked + label:before {
		content: "Active";
	}
	.active-switch input.cmn-toggle-round+label{
		width: 130px;
	}
	.active-switch input.cmn-toggle-round:checked+label:after{
		margin-left: 90px;
	}
	td{
		vertical-align: middle;
	}
	tr > td:last-child{
		position:relative;

		.close{
			position: relative;
			top: -2px;
			right: 0;
			bottom: auto;
		}
	}
	.input-group-addon{
		background: transparent;
		border: none;
	}

	.input-group{
		input{
			border-bottom-right-radius: 4px !important;
			border-top-right-radius: 4px !important;
			background: $white;
		}
	}
	label{
		@include font-size(1.5);
		color: #476e9a;
		font-weight: 300;
		padding: 0;
	}
	.form-group label{
		margin: 0;
	}
	footer{
		padding: 0 !important;
		.form-group {
			width: 100%;
			display: block;
			margin-top: 0 !important;
		}
	}


}



// * DASHBOARD PROFILE * //

.profile{
	h3{
		@include font-size(1.8);
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 5px;
	}
	.form-group{
		min-height: 85px;
		margin-bottom: 10px;
	}
}
#agreement{
	background: #ececec;
	padding: 30px 15px 0 15px;

	> div{
		background: $white;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px;

	}
	h2{
		margin: 15px;
		padding: 15px 0;
		display: block;
		border-bottom: 1px solid $silver;
		@include font-size(2.1);
	}
	p, small{
		padding: 5px 15px;
		color: #489fb1;
	}
	.form-group{
		padding: 15px;
	}
	span.glyphicon{
		margin-right: 10px;
	}
}


.form-inline > .form-group {
	float: left;
}

// .form-inline input.form-control {
// 	width: 100%;
// }

.form-inline label {
	display: block;
	float: left;
	margin: 50px 50px 0 0;
}

.picture-component-here {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 1px dashed $dark-grey;
	padding: 58px 10px 0 10px;
	@include font-size(1);
	float: left;
	background: $darker-grey url(../img/user-shape-plus.png) no-repeat 50% 15px;
	cursor: pointer;
}

.picture-preview {
	display: inline-block;
	height: 120px;
	width: 120px;
	margin-top: 15px;
	button.close {
		left: auto;
		right: auto;
		margin-left: -15px;
		bottom: 110px;
	}
	img {
		max-height: 120px;
		overflow: hidden;
	}
}

.boat-pictures {
	.picture-component-here,
	.video-component-here {
		margin-bottom: 15px;
		font-size: 15px;
		border-radius: 4px;
		width: 166px;
		height: 113px;
		left: 15px;
		top: 0px;
		background: $darker-grey url(../img/camera-shape.png) no-repeat 50% 15px;
	}

}

#notifications .row{
	padding: 30px;
}
#notifications footer.row{
	padding: 0;
}

#notifications table{
	thead>tr>th, tbody > tr:first-child td{
		border: none;
	}
	th, td{
		font-weight: 300;
		vertical-align: middle;
		padding: 10px 5px;
	}

	th, td{
		text-align: center;
	}
	tr td:first-child{
		text-align: left;
	}
}

.intl-tel-input {
	width: 100%;
}


// ------------------------------------------------------------------------
// CANCELLATION POLICY
// ------------------------------------------------------------------------

.cancellation-policy {
	position: relative;

	.gradient-bar {
		position: absolute;
		top: 225px;
		left: 0;
		z-index: 100;
		height: 50px;
		width: 100%;
		background: url(../img/gradient-bar.png) no-repeat 50% 0px;
	}

	p.intro {
		color: $sugar-blu;
	}

	article {
		background-color: transparent !important;
		margin: 0;
		padding: 0!important;
		z-index: 200;

		.ellipse {
			width: 24px;
			height: 25px;
			margin-bottom: 30px;
		}

		a {
			padding: 15px;
		}
		a:hover {
			background-color: $white;
		}

		a:active {
			background-color: $light-blu;
			//ALSO CHANGE children COLOR to $white WITH JQUERY.
		}

		a.active {
			background-color: $white;
		}
	}
	h2 {
		@include font-size(1.8);
		color: $black;
		margin-bottom: 0!important;
	}
	cite {
		color: $dark-grey;
		display: block;
		margin-bottom: 30px;
	}

	figure {
		width: 81px;
		margin: 0 auto;
		margin-bottom: 60px;
	}
}

.thumbnail {
	border: 0;
	background-color: transparent;
}

// ------------------------------------------------------------------------
// FORMS LAYOUT
// ------------------------------------------------------------------------


//CUSTOM SELECTS
.parent-select{
	overflow:hidden;
}

.parent-select select{
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 6px 12px;
	background: $white url(../img/select-shape.png) no-repeat 98% center;
}

.mandatory {
	color: $form-label;
}

label {
	color: $form-label;
	@include font-size(1.5);
	font-weight: 300;
}

.help-block {
	color: $form-help-text;
	margin-bottom: 0;
	margin-top: 3px;
	@include font-size(1.2);

	&.error {
		color: $branding-red !important;
	}
}

form h2 {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	@include font-size(1.7);
	font-weight: 300;
	color: $dark-grey;
	margin-top: 0;
}

form fieldset legend, form h3 {
	color: $default;
}

form footer {
	/*margin: 70px 0!important;*/
	.inner-footer {
		border-top: 1px solid silver;

		label {
			margin-top: 5px;
		}

	}
}

form input.next {
	width: 18.75%;
}

input.date{
	background: url(../img/calendar-icon.png) no-repeat 95% 50%;
	background-size: 14%;
}
input#date, input#dateInput2, input#dateInput1, input#dateInput3{
	padding-left: 35px;

}
.focused {
	background: $white;

}


.checkbox{
	margin-bottom: 20px;
	input {
		margin-top: 5px;
	}
}

// .form-group {
// 	overflow: hidden;
// }

input[type=checkbox]
{
	padding: 10px;
	&:focus {
		outline: none;
	}
}

input[type=radio] {
 -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

.checkbox-inline{
	margin-right: 30px;
	padding-left: 40px;

	input[type="checkbox"]{
		margin-top: 8px;
		display: block;
		float: left;
		width: auto;
		height: auto;
	}
}

.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 8px 10px;
	margin-left: -1px;
	position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
	border-top-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0;
	border-bottom-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 3px;
	left: 5px;
	@include font-size(0.9);
	font-weight: normal;
}

// ------------------------------------------------------------------------
// TABS LAYOUT
// ------------------------------------------------------------------------
// .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus{
// 	border: 1px solid $white;
// }
.nav-tabs{
	border-bottom: none;
}
.tab-pane fieldset.row{
	padding: 5px 15px;
}

// ------------------------------------------------------------------------
// WIZARD LAYOUT
// ------------------------------------------------------------------------

.wizard-count {
	background-color: $blu;
	overflow: hidden;
	text-align: center;
		/*margin-bottom: 85px;*/

		li {
			float: left;
			display: block;
			width: 25%;
			height: 70px;
			color: $white;
			@include font-size(2.2);

			span {
				@include font-size(1.6);
				padding: 3px 10px;
				margin-right: 15px;
				border: 1px solid $white;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
			}
			a {
				color: $white;
				height: 70px;
				display: block;
				padding: 20px 0;
			}
			&.active a {
				border-bottom: 5px solid $branding-red;
			}

			&.active a, a:hover {
				span {
					border-color: $branding-red;
				}
				background-color: $dark-blu;
			}
		}
	}

	.boat-shape {
		background: url(../img/boat-shape.png) no-repeat 30% 0;
	}

	.dashboard .boat-shape {
		background-position: 55% 20%;
	/*background-size: 43%;*/
}

.fieldgroup {
	margin-bottom: 80px;

	h2, legend {
		border-bottom: 1px solid silver;
		width: 100%;
		padding-bottom: 10px;
		margin-bottom: 10px;
		@include font-size(2.2);
	}
	legend {
		line-height: 1.1;
	}
	fieldset {
		margin-bottom: 20px;
	}
}

.form-group {
	margin-bottom: 30px;
}

#gmap_canvas1 {
  height: 350px;
  width: 100%;
}

#gmap_canvas {
	height: 350px;
	width: 100%;

	.centerMarker {
		position: absolute;

	/*url of the marker*/
	background: url(../img/marker.png) no-repeat;

	/*center the marker*/
	top: 50%;
	left: 50%;
	z-index: 1;

	/*fix offset when needed*/
	margin-left: -17px;
	margin-top: -35px;

	/*size of the image*/
	height: 35px;
	width: 35px;
	/*cursor:pointer;*/
}
}


#next {
	margin-top: 30px;
}

.wizard form {
	padding: 0 30px;
}
.form-group.error, .input-group.error{
	label, label span, input{
		color: $branding-red !important;
	}
}
#boat-tabs li {
	width: 20%;
	&.error a {
		color: $branding-red !important;
	}
}
.length {
	margin-top: 70px;
}

.guests {
	background: url(../img/user-shape.png) no-repeat 0 0;
	height: 30px;
	position: relative;
	top: 30px;
}

.crew {
	background: url(../img/crew-shape.png) no-repeat 0 0;
	height: 30px;
	position: relative;
	top: 30px;
}

.img-boat {
	&.ui-sortable-handle{
		cursor: move;
	}
	button.close{
		bottom: 100px;
		left: 0;
	}
	img {
		/*height: 113px;
		width: 194px; */
		max-width: 100%;
	}
	/*height: 113px;*/
	margin-bottom: 30px;
	margin-top: 30px;

}

#boat-details button.close {
	opacity:1;
	left: 0px;
	bottom: 100px;
}

.profile {
	.form-group{
		min-height: 85px;
		margin-bottom: 15px;
	}
	.contact-agreement-button, .client-contract-button {
		position: relative;
		overflow: hidden;
		input {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			padding: 0;
			font-size: 20px;
			cursor: pointer;
			@include transparent(0, 0);
		}
	}
}
.row.spoken-languages{
	margin: 0;
	h3{
		width: 100%;
		padding-left: 0;
	}
}

label[for="mobile-phone"], label[for="company-phone"]{
	width: 100%;
}
#prefix, #company-prefix{
	width: 20%;
	float: left;
}
#mobile-phone, #company-phone{
	width: 75%;
	margin-left: 5%;
	float: left;
}
#boats-number{
	width: 40%;
}

// * TABLE PRICELIST * //

.row.scrollable{
	margin: 0;
	overflow-x: auto;
}

.scrollable::-webkit-scrollbar {
	-webkit-appearance: none;
}

.scrollable:-webkit-scrollbar:vertical {
	width: 11px;
}

.scrollable::-webkit-scrollbar:horizontal {
	height: 11px;
}

.scrollable::-webkit-scrollbar-thumb {
	border-radius: 8px;
	border: 2px solid $white; /* should match background, can't be transparent */
	background-color: rgba(0, 0, 0, .5);
}

.scrollable::-webkit-scrollbar-track {
	background-color: $white;
	border-radius: 8px;
}

#wizard-pricelist{
	margin-bottom: 30px;

	th:first-child{
		border-top: 1px solid $white;
		border-left: 1px solid $white;
	}

	th.btn-primary{
		width: 20px;
		min-width: 0;
		background-color: $light-blu;
		position: relative;
		a{
			position:absolute;
			padding: 28px 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: $white;
			@include font-size(2.1);
			font-weight: bold;

		}
	}
	td:first-child{
		min-width: 250px;
	}
	.set-price{
		background: transparent;
		border: none;
		box-shadow: none;
		text-align: center;
	}
}

#extras > p{
	margin-bottom: 60px;
}

button.close{
	background: $branding-red;
	opacity: 1;
	color: $white !important;
	padding: 3px 7px;
	border-radius: 50%;
	font-weight: 300;
	position: absolute;
	right: 0;
	bottom: 35px;
}


.fieldgroup fieldset.extra-row{
	margin-bottom: 0;
	padding: 30px 0;
	border-top: 1px solid $silver;
	position: relative;

	.form-group{
		display: table-cell;
		padding: 0 !important;

		> *{
			padding-top: 0;
			padding-bottom: 0;
			margin-top: 0;
			padding-bottom: 0;
		}
	}


	input[type="text"]{
		margin-right: 15px;
	}

	label.checkbox-inline{
		margin-right: 15px;
	}


	.input-group-addon{
		background: transparent;
		border: none;
	}

	.input-group .form-control{
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
	}
}

.add-row{
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid #ddd;
	@include clearfix;
}
.wizard .form-inline #next{
	width: 100%;
	button, label, input{
		float: left;
	}
	.next{
		width: 18.5%;
	}
}



// * NEGOTATIONS * //

.negotiations form{
	padding: 0;


}
.all-negotiations{
	margin: 30px 0;
	border-bottom: 1px solid $silver;
	th{
		font-weight: 300;
		color: $dark-grey;
		background-color: #f5f5f5;
	}
	tr {
		background-color: #ffffff;
	}
	td{
		min-width: 80px;
		font-weight: 400;
		@include font-size(1.3);
	}
	td:last-child{
		position: relative;
		span.badge{
			position: absolute;
			right: 5px;
			top: 30%;
		}
	}

	.odd {
		background-color: #f5f5f5;
	}
	tr a{
		display: block;
	}
	.time{
		display: block;
		color: $dark-grey;
		@include font-size(1.2);
	}
	.period{
		display: block;
	}
}
h3.conversation-year{
	color: $dark-grey;
	font-weight: 300;
}

// * TAGS SEARCH * //
input#tags{
	width: 100%;
}
.bootstrap-tagsinput {
	background-color: $white;
	border: 1px solid #ccc;
	padding: 5px 7px;
	margin-bottom: 10px;
	vertical-align: middle;
	border-radius: 4px;
	width: 100%;
	max-width: 100%;
	line-height: 20px;
	cursor: text;
}
.bootstrap-tagsinput input {
	border: none;
	box-shadow: none;
	outline: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
	width: auto !important;
	max-width: inherit;
}
.bootstrap-tagsinput input:focus {
	border: none;
	box-shadow: none;
}
.bootstrap-tagsinput .tag {
	margin-right: 2px;
	margin-bottom: 2px;
	color: $white;
	background-color: $light-blu;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
	margin-left: 8px;
	cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
	content: "\e083";
	font-family:"Glyphicons Halflings";
	color: $white;
	padding: 0 4px;
	@include font-size(0.9);
	text-align: center;
	border-radius: 50%;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.symbol {
	display: inline-block;
	border-radius: 50%;
	border: 2px solid $dark-grey;
	width: 20px;
	height: 20px;
	margin-right: 5px;
}

.dashboard .symbol {
	width: 17px;
	height: 17px;
}

.symbol-empty {
	background-color: transparent;
}

.symbol-filled {
	background-color: $branding-red;
	border: 2px solid $branding-red;
}


// * NEGOTIATIOS DETAILS *//

.negotiations-details #main section[role="header"],
.negotiations-list #main section[role="header"]{
	position: relative;
	background-color: $white;
	@include border-radius(2px);
	overflow: hidden;
	padding: 15px;
	h2{
		@include font-size(2.1);
		margin-bottom: 0;
	}

	.endless_more {
		@extend .btn;
		@extend .btn-primary;
		width: 90%;
		margin-left: 5%;
	}

	.date.form-control {
		text-align: right;
	}

	.common-questions h4 {
		position: relative;
		span{
			position: absolute;
			right: 0;
			top: 5px;
			font-size: 12px;
			font-size: 1.2rem;
			color: #ddd;
		}
	}

	.form-group {
		margin-bottom: 0;
	}
}


ul#negotiations-details{
	li{
		margin-bottom: 5px;
		height: 70px;
		padding-left: 0;
	}
}
span.client-image img{
	width: 30px;
	height: 30px;
	margin-right: 15px;
}
a#view-all{
	position: absolute;
	top: 10px;
	right: 15px;
}

.detail-border-bottom{
	background-color: $white;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	padding: 15px;
	margin: 15px;
}
.col-left{
	padding-left: 0;
}
.col-right{
	padding: 0;
}
.col-left > section.clear , .col-right > section.clear {
	background-color: $white;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	padding:15px;
	margin-top: 15px;
	&:first-child{
		margin-top: 0;
	}
}
.button .btn-primary{
	width: 100%;
}
.border-bottom{
	border-bottom: 1px solid $silver;
	padding-bottom: 15px;
	width: 100%;
	@include clearfix;
}
#main div.row article.message{
	background: none !important;
	margin: 30px 0;
	padding: 0;

	.time{
		float: right;
		color: $dark-grey;
	}

	.client{
		font-weight: 400;
	}

	div{
		background-color: $white;
		margin-left: 40px;
		padding: 10px;
		position: relative;

		p{
			margin-bottom: 0;
		}

		&:after{
			content: '';
			position: absolute;
			left: -5%;
			top: 10%;
			width:0;
			height:0;
			border-right:20px solid $white;
			border-top:10px solid transparent;
			border-bottom:10px solid transparent;
		}
	}

}

.negotiations-details.dashboard form {
	.fieldgroup {
		margin-bottom: 0;
		background-color: #ffffff;
	}
	.border-bottom {
		margin-top: 10px;
	}
}

h2.title {
	color: $sugar-blu;
	margin-top: 15px!important;
	margin-bottom: 15px;
	border: 0;
}
.new-prop {
	border-top: none;
	margin: 0;
	padding: 0;
}
.new-prop td {
	vertical-align: middle!important;
	border-top: 0!important;
	&:first-child{
		padding-left: 0;
		font-weight: 600;
		color: #647d92;
	}
	label{
		font-weight: 400;
		@include font-size(1.3);
	}
	label[for="boat-type"]{
		font-weight: 600;
		color: #647d92;
	}
}

.prop-message{
	margin-top: 10px;
}

#calendar{
	.fc-toolbar{
		margin-bottom: 0;
		border: 1px solid $silver;
		border-bottom: none;
		padding-top: 10px;

		*{
			border: none;
		}
	}
	.fc-left, .fc-right{
		border: 0;
	}
	.fc-left button{
		padding-left: 10px !important;
		padding-right: 0 !important;
	}
	.fc-left button{
		padding-right: 10px;
	}
	th{
		font-weight: 400;
		color: $dark-grey;
		padding: 5px 0;
	}
	tr{
		vertical-align: middle;
	}
	td{
		vertical-align: middle;
		text-align: center;
	}
}




// ------------------------------------------------------------------------
// LANDING PAGE
// ------------------------------------------------------------------------
.landing-body{
	.wrapper{
		min-width: initial;
	}
}

.landing-bar {
	@include font-size(1.8);
	padding: 20px 0 0;
	margin: 0;
	@media (max-width: 768px) {
		@include font-size(2.1);
	}
	a.navbar-link {
		text-decoration: underline !important;
		&:hover{
			color: $blu;
		}
	}
	span {
		@media (max-width: 768px) {
			display: none;
		}
	}
}

.landing{
	position: relative;
	padding: 0 0 40px 0;
	section{
		padding: 60px 30px;

		h2{
			font-family: $font-secondary;
			@include font-size(4.2);
			font-weight: 300;
			color: $blu;
		}
	}
	.jumbotron{
		background: url(../img/landing-bck.jpg) no-repeat 50% 0;
		background-size: cover;
		border-radius: 0;
		margin-bottom: 0;
		padding: 60px 30px;
		h1{
			font-family: $font-secondary;
			font-size: 48px;
			color: $white;
			text-shadow:  1px 1px $profile-blu;
			margin-top: 0;
		}
		p{
			@include font-size(1.6);
			color: $white;
			margin-bottom: 30px;
		}
		h2{
			@include font-size(2.1);
			color: $white;
			font-weight: 300;
			margin: 10px 0;
			font-family: $font-primary;
		}

		div.canvas{
			position: relative;
			padding-bottom: 56.25%;
			height: 0;
			overflow: hidden;
			iframe{
				position: absolute;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		#sign-up{
			padding: 0;
			.form-group{
				padding: 0 7.5px;
				margin-bottom: 10px;

				label, br{
					display: none;
				}
			}
			input, select{
				background-color: rgba(255,255,255, .5);
				color: $blu;
				border: 1px solid $white;
				&:focus{
					background-color: rgba(255,255,255, 1);
					opacity: 1;
				}
			}
			button[type="submit"]{
				background-color: $branding-red;
				border: 1px solid $branding-red;
				color: $white;
				width: 100%;
				padding: 10px 0;
				font-weight: 400;
				@include font-size(1.8);

				&:hover{
					background-color: darken($branding-red, 15%);
					border-color: darken($branding-red, 15%);
				}
				&:active{
					background-color: darken($branding-red, 30%);
					border-color: darken($branding-red, 30%);
				}
			}

		}
		#facebook-signup{
			padding: 10px 5px;
			border: none;
			color: $white;
			font-weight: 300;
			@include font-size(1.2);
			width: 50%;
			margin: 20px 5px 0 5px;
			float: left;
			display: table-cell;
			vertical-align: middle;
			span{
				@include font-size(1.3);
			}
			background-color: rgba(62, 122, 178, 1);
			&:hover{
				background-color: darken(rgba(62, 122, 178, 1), 15%);
				border-color: darken(rgba(62, 122, 178, 1), 15%);
			}
		}
		#google-signup{
			padding: 10px 5px;
			margin-top: 20px;
			background-color: #c5122b;
			border: none;
			color: $white;
			font-weight: 400;
			@include font-size(1.2);
			width: 45%;
			float: left;
			display: table-cell;
			vertical-align: middle;
			span{
				@include font-size(1.3);
			}
			background-color: rgba(197, 18, 43, 1);
			&:hover{
				background-color: darken(rgba(197, 18, 43, 1), 15%);
				border-color: darken(rgba(197, 18, 43, 1), 15%);
			}
		}
		.separator{
			color: $white;
			margin-top: 0;
			padding: 0;
			position: relative;

			.line{
				border-bottom: 1px solid $white;
				display: inline-block;
				height: 5px;
				width: 130px;
				min-width: 80px;
			}
			small{
				display: inline-block;
				margin: 0 10px;
				position: relative;
				bottom: -3px;

			}

		}
	}

	.landing.access {
		padding-bottom: 0 !important;
	}

	.icons{
		background-color: $white;
		article{
			.img-container{
				width: 65%;
				margin: 30px auto 15px auto;
				img{
					width: 100%;
				}
			}
			h3{
				@include font-size(2.1);
				font-weight: 300;
			}
		}
	}

	.icon-percent, .sailogy{
		font-weight: bold;
	}

	@font-face {
		font-family: 'icomoon';
		src:url('fonts/icomoon.eot?gwad2r');
		src:url('fonts/icomoon.eot?#iefixgwad2r') format('embedded-opentype'),
		url('fonts/icomoon.woff?gwad2r') format('woff'),
		url('fonts/icomoon.ttf?gwad2r') format('truetype'),
		url('fonts/icomoon.svg?gwad2r#icomoon') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	[class^="icon-"], [class*=" icon-"] {
		font-family: 'icomoon';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-file-pdf:before {
	content: "\f014";
}
.icon-file-submodule:before {
	content: "\f017";
}
.icon-file-symlink-directory:before {
	content: "\f0b1";
}
.icon-file-symlink-file:before {
	content: "\f0b0";
}
.icon-file-text:before {
	content: "\f011";
}
.icon-file-zip:before {
	content: "\f013";
}

.how-it-works{
	background-color: $blu;
	color: $white;

	h2{
		padding-bottom: 120px;
		background: url(../img/how-it-works.png) no-repeat 50% 100%;
		background-size: contain;
		margin-bottom: 0;
		color: $white;
	}

	article{
		padding: 15px;
	}
	h3{
		font-weight: 300;
		margin-bottom: 30px;
		height: 50px;
	}
	p{
		@include font-size(1.5);
		color: rgba(255,255,255, .5);
		font-weight: 300;
	}

}
.features{
	background-color: $white;
	h2{
		color: $blu;
		margin-bottom: 30px;
	}
	div.row{
		margin-bottom: 90px;
		aside img{
			display: block;
			width: 100%;
			box-shadow: 2px 2px 5px $light-grey;
		}
		article p{
			@include font-size(1.8);
		}
	}
}
.common-questions{
	background-color: $light-grey;
	article.open{
		display: block;
	}
	h2 {
		color: $blu;
		margin-bottom: 30px;
	}
	.panel{
		background: transparent;
		border: none;
		padding: 0;
	}
	.panel-heading {
		background-color: #d5d5d5;
		border-radius: 4px;
		margin: 30px 0 0 0;
		.panel-title{
			position: relative;
			span.glyphicon{
				position: absolute;
				right: 0;
				top: 5px;
				@include font-size(1.2);
				color: #bbbbbb;
			}
		}
		a {
			@include font-size(1.8);
		}
	}
	.panel-body {
		padding: 15px;
		background-color: $white;
		border-top: none;
		border-bottom: none;
		border-radius: 4px;
	}
	a.btn {
		text-align: center;
		background-color: $branding-red;
		border: none;
		color: $white;
		padding: 10px 50px;
		margin-top: 40px;
		&:hover {
			background-color: darken($branding-red, 15%);
		}
	}
}

.about-us{
	background-color: $white;
	h2{
		color: $blu;
		margin-bottom: 60px;
	}
	h3{
		@include font-size(6);
		color: $black;
		font-weight: 300;
	}
	.img-container{
		width: 65%;
		margin: 30px auto 15px auto;
		img{
			width: 100%;
		}
	}
	p.lead{
		color: $black;
		font-weight: 400;
		@include font-size(2.1);
	}
}

.people-comment{
	background-color: $light-grey;
	display: none;
	.speech{
		margin-bottom: 30px;
		aside{
			img{
				width: 100%;
			}
		}
		.row div p{
			padding: 10px;
			background-color: $white;
			border-radius: 4px;
			box-shadow: 2px 2px 5px $light-grey;
			position: relative;
			&:after{
				content: '';
				position: absolute;
				left: -5%;
				top: 10%;
				width: 0;
				height: 0;
				border-right: 20px solid $white;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
			}
		}
		h4 {
			@include font-size(2.1);
		}
		h5 {
			color: $dark-grey;
		}
	}
}
.partners {
	background-color: $light-grey;
	h2 {
		color: $blu;
		margin-bottom: 60px;
	}
	.logo-container {
		display: table-cell;
		float: none;
		width: 1%;
	}
	span {
		display: block;
		padding: 0 30px;
		width: 100%;
		@media (max-width: 992px) {
			padding: 0 15px;
		}
		@media (max-width: 768px) {
			padding: 0 10px;
		}
		img{
			width: 100%;
		}
	}
	p {
		padding-top: 40px;
		margin-top: 40px;
		border-top: 1px solid #d5d5d5;

		a {
			color: $sugar-blu;
			font-weight: 500;
		}

		a:hover {
			color: $blu;
		}
	}
}
.start-now{
	padding: 30px;
	background-color: $branding-red;
	overflow: hidden;
	h3 {
		color: $white;
		@include font-size(1.8);
		position: relative;
		margin-top: 8px;
		@media (max-width: 992px) {
			text-align: center;
			margin: 10px 0 20px;
		}
	}
	a {
		background-color: $white;
		border: 1px solid $white;
		color: $branding-red;
		@include font-size(1.8);
		&:hover{
			border: 1px solid $white;
			background-color: $branding-red;
			color: $white;
		}
		&:active{
			border: 1px solid $white;
			background-color: #cb450f;
			color: $white;
		}
	}
}
.manual {
	padding: 30px;
	background-color: $light-blu;
	overflow: hidden;
	img {
		float: left;
	}
	h3{
		color: $white;
		@include font-size(1.8);
		position: relative;
		width: 65%;
		float: left;
	}
	a {
		background-color: $white;
		border: 1px solid $white;
		color: $light-blu;
		padding: 10px 60px;
		margin-top: 7px;
		@include font-size(1.8);
		&:hover{
			border: 1px solid $white;
			background-color: $light-blu;
			color: $white;
		}
		&:active{
			border: 1px solid $white;
			background-color: #cb450f;
			color: $white;
		}
	}
}

}
.switch{
	margin: 0 auto;
	display: inline-block;
}
.cmn-toggle {
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
}
.cmn-toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	user-select: none;
}


input.cmn-toggle-round + label {
	padding: 2px;
	width: 80px;
  /*height: 40px;*/
  height: 34px;
  background-color: $silver;
  @include border-radius-whole(4px);
  transition: background 0.4s;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
	display: block;
	position: absolute;
	content: "";
}
input.cmn-toggle-round + label:before {
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;
	background-color: $silver;
	border-radius: 60px;
	transition: background 0.4s;
	content: "No";
	color: $white;
	padding: 5px 40px;

}
input.cmn-toggle-round + label:after {
	top: 4px;
	left: 4px;
	bottom: 4px;
	width: 30px;
	background-color: $white;
	@include border-radius-whole(4px);
	transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round:checked + label {
	background-color: $light-blu;
}

input.cmn-toggle-round:checked + label:before {
	background-color: $light-blu;
	color: $white;
	content: "Yes";
	padding: 5px 13px;
	text-align: left;

}
input.cmn-toggle-round.inactive-parent:checked + label:before {
	background-color: $silver;
}
input.cmn-toggle-round:checked + label:after {
	margin-left: 40px;
	background-color: $white;
}

.active-switch input.cmn-toggle-round + label:before {
	content: "Disabled";
	padding: 5px 40px;
}

.active-switch input.cmn-toggle-round:checked + label:before {
	content: "Active";
}
.active-switch input.cmn-toggle-round+label{
	width: 150px;
}
.active-switch input.cmn-toggle-round:checked+label:after{
	margin-left: 110px;
}


// * SUPPORT AND FAQ PAGE * //

#topics{
	padding: 0;
	margin-top: 30px;
}
#faq{
	background-color: $white;
	padding: 0;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;

	.accordion{
		padding: 5px 0;
		margin-bottom: 0;
	}
	.panel-group{
		margin-bottom: 0;
	}
	.panel{
		border: none;
		padding: 0;
		padding: 15px 30px;
		border-bottom: 1px solid $darker-grey;
		border-radius: 0;
		&:last-child{
			border-bottom: 0;
			box-shadow: none;
		}

	}
	.panel-heading{
		padding: 0;
		background: $white;
		border: none;
	}
	.panel-body{
		border-top: none;
		padding:15px 0;
	}
	h4{
		@include font-size(1.8);
		font-weight: 300;
		position: relative;

		span{
			position: absolute;
			right: 0;
			top: 5px;
			@include font-size(1.2);
			color: #bbbbbb;
		}
	}

	ul {
        list-style-type:circle;
    }

    li {
        margin-left:15px;
    }
}

#more-support{
	padding: 15px;
	background-color: $white;
	margin: 15px 0;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;

	h2{
		color: #476e9a;
		@include font-size(1.8);
	}
	.help-block{
		color: #489fb1;
	}
}

#contact{
	margin: 15px 0;
	padding: 15px;
	background-color: $white;
	h2{
		color: #476e9a;
		@include font-size(1.8);
	}
	address{
		font-weight: 400;
		text-align: center;
		@include font-size(1.4);
		h4{
			font-family: $font-secondary;
			color: #476e9a;
		}
		span{
			display: block;
			margin: 5px 0;
		}
	}
	a{
		color: #333333
	}
}

// * vertical tabs ** //


.tabs-left {
	border-bottom: none;
	padding-top: 2px;
}
.tabs-left {
	border-right: 1px solid #ddd;
}

.tabs-left>li{
	float: none;
	margin-bottom: 2px;
}
.tabs-left>li {
	margin-right: -1px;
}

.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
	border-bottom-color: $white;
	border-right-color: transparent;
}

.tabs-left>li>a {
	border-radius: 4px 0 0 4px;
	margin-right: 0;
	display:block;

	&:hover{
		background-color: #d0d0d0;
		color: #707070;
	}
}

.sideways {
	margin-top:50px;
	border: none;
	position: relative;
}
.sideways>li {
	height: 20px;
	width: 120px;
	margin-bottom: 100px;
}
.sideways>li>a {
	border-bottom: 1px solid #ddd;
	border-right-color: transparent;
	text-align: center;
	border-radius: 4px 4px 0px 0px;
}
.sideways>li.active>a,
.sideways>li.active>a:hover,
.sideways>li.active>a:focus {
	border-bottom-color: transparent;
	border-right-color: #ddd;
	border-left-color: #ddd;
}
.sideways.tabs-left {
	left: -50px;
}

.sideways.tabs-left>li {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}


// * DASHBOARD DISCOUNTS AND EXTRAS  *//

.input-group-addon{
	background: transparent;
	border: none;
}
.input-group input.form-control{
	border-bottom-right-radius: 4px !important;
	border-top-right-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	border-top-left-radius: 4px !important;
}

.contrast {
	background-color: $white;
	padding: 30px 15px;
	margin-bottom: 15px;
}

#main.discount-form, #main.extras-form{
	padding: 0 30px 30px;
	p{
		color: #489fb1;
	}
	.fieldgroup {
		margin-bottom: 30px;
	}
	form {

		h2{
			border-bottom: 0;
			color: #476e9a;
			margin: 0 0 30px 0;
			@include font-size(1.8);
		}
	}
}

#main.discount-form form, #main.extras-form form{
	footer{
		margin: 0 !important;
		position: static;
		.inner-footer{
			border: none;
		}
		.form-group{
			margin: 0;
			width: 100%;
			padding: 0;
			display: block !important;
			.inline-label{
				margin: 0;
			}
		}
	}

	label{
		@include font-size(1.5);
		margin: 0;
		color: #476e9a;
	}
	.form-group{
		> div{
			padding: 0;
		}
	}

}




#discount-form, #discounts{
	table{
		margin-bottom: 5px;
		tbody tr td{
			&:first-child{
				width: 45%;
				color: #476e9a;
			}
			&:last-child{
				position: relative;
				width: 5%;
			}
		}
		td{
			vertical-align: middle;
		}
	}

	.form-group{
		display: table-cell;
		vertical-align: middle;
		label{
			padding: 0;
		}
		input{
			width: 100%;
			margin-top: 4px;
			background-color: $white;
		}
	}

	.close{
		position: relative;
		top: -2px;
		right: 0;
		bottom: auto;
	}
	.fieldgroup.early{
		.form-group label{
			margin-top: 5px;
		}
		.form-group input{
			margin-top: 0;
		}
	}

	.date.form-control {
		text-align: center;
	}

	button.btn-danger {
		margin-bottom: 4px;
	}
}


// * DASHBOARD EXTRAS *//

#extras-form{
	p{
		color: #489fb1;
	}
	.input-group{
		float: left;
		display: block;
		input,.input-group-addon{
			float: left !important;
			max-width: 70px;
		}
	}
	.empty_form {
		display: none;
	}
	.removed .form-group, .removed .checkbox-inline, .removed .input-group-addon {
		opacity: 0.3;
	}
	.removed .unmark_form {
		display: none;
	}
	.extra-restore {
		// position: absolute;
		// top: 30px;
		// right: 10%;
		display: none;
	}
	.removed .extra-restore {
		display: block;
	}
	.help-text {
		padding-top: 20px;
	}
	.boat-extras-formsets-wrapper {
		position: relative;
	}
	#reset-boat-extras-mask {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ececec;
		opacity: 0.7;
	}
}
//* FLEET GALLERY & FLEET LIST *//

.fleet{
	padding: 0 30px 30px;
	.filters{
		border-bottom: 1px solid #ddd;
		padding-bottom: 15px;
		label{
			padding-left: 0;
		}
	}
	label{
		@include font-size(1.5);
		color: #476e9a;
		margin-top: 5px;
		input[type="checkbox"]{
			margin-left: -25px;
		}
	}
	.checkbox{
		margin: 5px 0;
	}
	.view-mode{
		margin-top: 5px;
		text-align: right;
		.btn{
			display: inline-block;
			margin: 0 5px;
		}
		.btn.active,.btn:hover, .btn:active{
			background-color: $branding-red;
			color: $white;
		}

	}
	span.label{
		display: block;
		padding: 5px;
		@include font-size(1.3);
		font-weight: 300;
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		position: absolute;
		top: 0;
		right: 10px;
		left: 10px;
		height: 25px;
		z-index: 99;
	}
	.description{
		h3{
			margin-bottom: 5px;
			@include font-size(1.6);
		}
		span{
			color: $default;
		}
	}
}

#main.fleet article{
	background-color: transparent !important;
	padding: 10px !important;
	margin: 30px 0;
	width: 25%;
	float: left;
	position:relative;
	min-height: 277px;

	> a{
		display:block;
		width: 100%;
		height: 100%;
	}

	.fleet-pic{
		height: 160px;
		overflow: hidden;
		@include border-radius-whole(4px);
		display: block;
		img{
			width: 100%;
			/* height: 95%; //make space for the .completeness element */
		}
		.completeness {
			height: 5%;
			display: block;
			width: 15%; //dynamic
			background-color: $blu; //dynamic
		}
	}

	.add-boat{
		background-color: $white;
		text-align: center;
		font-family: $font-secondary;
		height: 160px;
		color: black;
		padding: 20px 0;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		font-weight: 500;
		@include font-size(1.8);
		a{
			display: block;
			width: 100%;
			height: 100%;
		}
		.icon-add-boat{
			display: block;
			margin: 0 auto;
			@include font-size(6);
		}
	}
	.edit{
		padding: 10px;
		background-color: rgba(0,0,0, .3);
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		height: 160px;
		@include border-radius-whole(4px);
		.btn{
			display: block;
			width: 70%;
			margin: 5px 15%;
			&:last-child{
				background-color: $branding-red;
				border-color: $branding-red;

				&:hover {
					background-color: darken($branding-red, 15%);
					border-color: darken($branding-red, 15%);
				}
			}
		}
		.btn.remove:hover{
			background-color: #3071a9;
			border-color: #285e8e;
		}
	}
	span.not-online{
		background-color: #d43500;
	}
	span.archived{
		background-color: silver;
	}
}

.fleet-list{
	border-bottom: 1px solid #ddd;
	margin-top: 30px;
	th{
		font-weight: 300;
	}
	tr, td{
		vertical-align: middle !important;
	}
	td:first-child{
		overflow: hidden;
	}
	.fleet-pic{
		height: 50px;
		width: 50px;
		overflow: hidden;
		border-radius: 4px;
		display: block;
		float: left;
		margin-right: 10px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	h3{
		display: inline-block;
		float: left;
		@include font-size(1.5);
	}
	small.label{
		padding: 0;
		font-weight: bold;
		display: inline-block;
		padding-left: 15px;
	}
	button{
		background-color: transparent;
		border: none;
		&:hover{
			color: $branding-red;
		}
	}
	small.not-online{
		color: #d43500;
	}
	small.archived{
		color: silver;
	}
}


// * PRICELIST ** //

.dash-pricelist{
	margin-bottom: 0;
	th, td{
		text-align: center;
		vertical-align: middle;
		padding: 10px;
		min-width: 180px;
	}
	th:first-child, td:first-child{
		text-align: left;
	}

	h3{
		@include font-size(1.3);
		margin: 5px 0;
	}
	small{
		font-weight: 300;
		@include font-size(1.4);

	}
	td{
		width: auto;
	}

	.form-group{
		max-width: 150px;
		input{
			width: 100%;
			background-color: #f4f4f4;
			font-weight: 300;
		}
	}
	th:last-child{
		background-color: $light-blu;
		position: relative;
		padding: 0;
		a{
			display: block;
			width: 100%;
			padding: 30px 0;
			color: $white;
			@include font-size(1.3);
			font-weight: 300;

		}
	}
}
#season-modal, #price-modal{
	.modal-header{
		position: relative;
		.close{
			opacity: 1;
		}
	}
	.modal-body{
		overflow: hidden;
	}
	.form-group{
		margin-bottom: 10px;
		max-width: 100%;
	}
	.form-group input{
		background-color: #f4f4f4;
	}
	label{
		color: #476e9a;
	}
}
#price-modal{
	h3{
		@include font-size(1.8);
	}
	p{
		color: #489fb1;
		margin-bottom: 15px;
	}
	h4{
		@include font-size(1.6);
		color: #476e9a;
	}
	label{
		font-weight: 300;
		padding: 10px 30px;
		input[type="radio"]{
			margin-left: -25px;
		}
		&.radio-inline{
			padding-left: 0;
		}
	}
}
button.set{
	height: 100%;
	width: 100%;
	border: none;
	padding: 2px;
	@include font-size(1.8);
	font-weight: 300;
	background: none;
}
.grey{
	background-color: #ddd !important;
}

// ------------------------------------------------------------------------
// CALENDAR
// ------------------------------------------------------------------------

#full-calendar{
	border-top: 1px solid $silver;
	padding-top: 0;
	a.prev,a.next{
		display: inline-block;
		cursor: pointer;
		font-weight: 300;
	}
	a.next{
		margin-left: 10px;
		margin-right: 0;
	}
	a.prev{
		margin-left: 0;
		margin-right: 10px;
	}
	tbody{
		border: 1px solid $silver;
	}
	th{
		border: 1px solid $silver;
		text-align: center;
		font-weight: 300;
		&:first-child{
			border: none;
			min-width: 130px;
		}
	}
	tr{
		td{
			cursor: pointer;
			h3{
				@include font-size(1.8);
				font-weight: 300;
				margin: 5px 0;
			}
			span{
				color: $dark-grey;
			}
			&:first-child{
				width: 30%;
				border-top: none;
				border-bottom: 1px solid$silver;
				text-align: left;
				position: relative;
				height: 90px;
			}
			border-right: 1px solid $silver;
			background-color: $white;
			vertical-align: middle;
			text-align: center;
		}
		td.event_cell {
			width: 11%;
		}

	}
	span.label{
		display: block;
		text-align:left;
		padding: 2px 5px;
		position: absolute;
		border-radius: 0 !important;
		bottom: 0;
		left: 0;
		right: 0;
		&.not-online{
			background-color: #d43500;
			color: $white;
		}
	}

	table.daily{
		th {
			overflow: hidden;
			padding: 0;
			span{
				display: block;
				float: left;
				padding: 12px 0;
				width: 20%;
				margin: 0;
				text-align: center;
				border-right: 1px solid $silver;
				height: 100%;
				@include font-size(1.1);
				&:last-child{
					border-right: 0;
				}
			}
		}
		td{
			width: 14%;
		}
		td:first-child{
			width: 30%;
			border-top: none;
			border-bottom: 1px solid$silver;
			text-align: left;
			position: relative;
			height: 80px;
		}
	}

}

.filtering{
	padding: 0;
}
#filtering{
	.form-group{
		margin: 5px 0;
	}
	position: relative;
	bottom: -50px;
	label{
		color: #476e9a;
	}
}
.month{
	position: relative;
	bottom: -60px;
	color: #476e9a;
	left: 10px;
}
.calendar-mode{
	position: relative;
	padding: 0;
	right: -15px;
	bottom: -50px;
	text-align: right;

	.btn{
		display: inline-block;
		margin: 0 2px;
		color: #476e9a;

	}
	.btn.active,.btn:hover, .btn:active{
		background-color: $branding-red;
		color: $white;
	}
}
#legend{
	article{
		margin-right: 20px;
		float: left;
		background-color: inherit !important;
	}
	article > div{
		display: inline-block;
		width: 30px;
		height: 30px;
	}
	article span{
		display: inline-block;
		position: relative;
		top: -10px;
		left: 2px;
	}
}

#booking-popup { min-height: 500px; }
#booking-popup section{
	margin: 15px 0;
	border-bottom: 1px solid $light-grey;
	padding-bottom: 15px;

	input[type="text"], textarea{
		border: 1px solid #bababa;
		background-color: #f4f4f4 !important;
	}

	input[type="radio"]{
		border: 1px solid #bababa !important;
		background-color: #f4f4f4 !important;
	}

	input.date{
		padding-left: 35px;
	}

	.nav-tabs>li.active a{
		background-color: $light-blu;
		border: 0;
		color: $white;
	}
}
section.interval{
	h3{
		color: #476e9a;
		@include font-size(1.9);
		font-weight: 300;
		margin: 0;
	}
	.form-group{
		margin: 0;
	}
	label{
		display: inline-block;
		padding-left: 5px;
	}
	input{
		display: inline-block;
		max-width: 130px;
	}
}

section.status{
	h3{
		color: #476e9a;
		@include font-size(1.9);
		font-weight: 300;
		margin: 0;
	}
	label{
		color: #476e9a;
		font-weight: 300;
	}

	.radio label{
		padding: 10px 0 10px 35px;

		input[type="radio"]{
			margin-left: -25px;
		}
	}
}
section.notes{
	h3{
		color: #476e9a;
		@include font-size(1.9);
		font-weight: 300;
		margin: 0;
	}

}
.final-price, .additional-discount{
	.form-group{
		padding: 5px;
		margin: 0;
	}
	input[type="text"]{
		display: inline-block;
	}
	label{
		font-size: 14px !important;
		font-size: 1.4rem !important;
		color: #476e9a;
	}
}

#booking-popup .modal-footer{
	border-top: 0;
	span.glyphicon{
		display: inline-block;
		margin-right: 5px !important;
	}
}

.glyphicon-ok-sign, .glyphicon-ok-circle, .glyphicon-remove, .glyphicon-th, .glyphicon-th-list{
	display: inline-block;
	margin-right: 5px !important;
}
#booking-popup .modal-header{
	position: relative;
	.close{
		opacity: 1;
	}
}

#optioned label{
	font-size: 14px !important;
	font-size: 1.4rem !important;
	color: #476e9a;
}
#optioned .form-group{
	margin: 15px 0;
}
.customer label{
	padding-left: 0 !important;
}
// * RIGHT COLUMN * //

#helpers{
	background-color: #dadada;
	margin-left: -15px;
	padding: 0;
	article{
		overflow: hidden;
		padding: 15px 10px !important;
		margin-bottom: 0;
		background-color: transparent !important;
		@include font-size(1.1);
		font-weight: 400;
		position: relative;

		span{
			@include font-size(1.4);
			width: 5%;
			margin-right: 5px;
		}
		p{
			width: 82%;
		}

		&.suggestion {
			border-bottom: 1px solid #3b86cd;
			span{
				color: #3b86cd;
			}
		}
		&.error {
			border-bottom: 1px solid #e72449;
			span{
				color: #e72449;
			}
		}
		&.warning {
			border-bottom: 1px solid #198c26;
			span{
				color: #198c26;
			}
		}

		button.closing{
			position: absolute;
			top: 0;
			right: 0;
			color: $dark-grey;
			@include font-size(1.4);
			background: none;
			border: none;
			box-shadow: none;
		}
	}
}

// Fix for datepicker //
.dropdown-menu { z-index: 9999 !important; }

// Fix autocomplete for boat model //
.visible-autocomplete.form-group.parent-select { overflow: visible; }

// register - login //

.access.landing {
	padding-bottom: 0;
	height: 100%;
	width: 100%;
}
.access.landing .jumbotron {
	width: 100%;
	background-image: url(../img/access-bckg.jpg);
	height: 100%;
	display: inline-block !important;
	vertical-align: middle !important;
}
.access.landing .jumbotron .opacity {
	background: rgba(255,255,255,.8);
	padding: 20px;
	border-radius: 10px;
}
.access.landing .jumbotron h1 {
	color: #101040;
	text-shadow: none;
	font-size: 26px;
	font-weight: 100;
}
.access.landing .jumbotron p {
	color: #101040;
}
.access.landing .jumbotron .separator {
	padding: 20px 0;
	color: #101040;
}
.access.landing .jumbotron .separator .line {
	border-color: #101040;
}
.access.landing .jumbotron .checkbox {
	color: #101040;
}

.access.landing .jumbotron > .row {
	position: relative;
	top: 45%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	margin: 20px;
}

.landing img.header {
	width: 100%;
}

.landing p.terms-and-conditions {
	font-size: 90%;
	text-align: center;
	color: #fff;
	font-weight: 100;
	clear: both;
}
.landing p.terms-and-conditions a {
	text-align: center;
	color: #fff;
	font-weight: 600;
}
.landing p.terms-and-conditions a:hover {
	color: $sugar-blu;
}
.access.landing .jumbotron .text-center a {
	text-decoration: underline !important;
}
@media (max-width: 1200px) {
	.access .col-sm-5 {
		width: 50%;
	}
}
@media (min-width: 993px) and (max-width: 1200px) {
	.access .col-sm-5 {
		margin-left: 25%;
	}
}
@media (max-width: 992px) {
	.access .col-sm-5 {
		width: 100%;
		padding: 20px 0;
	}
	.landing .manual{
		img {
			display: block;
			margin: 10px auto;
			float: none;
		}
		h3 {
			width: 100%;
			text-align: center;
		}
	}
}
@media (max-width: 768px) {
	.access .col-sm-5 {
		width: 100%;
	}

}

input::-webkit-input-placeholder {
	color: $sugar-blu !important;
}
input::-moz-placeholder {
	color: $sugar-blu !important;
}
input:-moz-placeholder {   /* Older versions of Firefox */
	color: $sugar-blu !important;
}
input:-ms-input-placeholder {
	color: $sugar-blu !important;
}

// DASHBOARD HOMEPAGE - chart legend
.legend {
	margin: 10px;
	border: solid 1px #eee;
	padding: 10px;
	.legend-item {
		width: 50%;
		float: left;
		span {
			height: 16px;
			width: 16px;
			display: block;
			float: left;
			margin-right: 10px;
			margin-top: 2px;
			border: solid 1px #999;
		}
	}
}
