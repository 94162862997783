.fonts-loaded {
  .chatlio-widget {
    font: {
      family: $basic_font !important;
    }
  }

  #chatlio-widget {
    .chatlio-message-body {
      font-weight: 400 !important;
    }

    textarea {
      font: {
        family: $basic_font !important;
      }
    }
  }
}

#chatlio-widget {
  //@extend .container;
  position: relative;
  .chatlio-widget {
    box-shadow: none !important;
    .chatlio-title-bar {
      background-color: $colorInfo;
      &:hover, &:focus {
        background-color: getColor(info, darker);
      }
    }
    right: 5px;
    &:not(.closed) {
      /*@include MQ(mS) {
        width: 100% !important;
        right: 0 !important;
      }*/
    }
    &.closed {
      bottom: 5px;
      min-height: 57px;
      border-radius: 0;
      .chatlio-up-arrow {
        &.closed {
          //margin-top: 18px;
          display: none;
        }
      }
      .chatlio-title-bar {
        border-radius: 0;
        box-shadow: none !important;
        .chatlio-controls {
          top: 15px;
        }
        .chatlio-title-text {
          min-height: 57px;
          a {
            line-height: 57px;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 90px);
            margin-left: 60px;
          }
          .chatlio-title-avatar-container {
            img {
              width: 40px;
              height: 40px;
              top: 9px;
              left: 10px;
            }
          }
        }
      }
    }
    /*&:not(.closed) {
      @include MQ(mM) {
        left: 15px;
        width: calc(100% - 30px) !important;
      }
    }*/
  }
}
