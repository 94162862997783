// Fonts
// Description: Add custom fonts here if importing and assign them via the @font-primary, @font-secondary variables for use with the .custom-font mixin

// Declare a Primary and Secondary font selection here

// $font-primary:
// $font-secondary:


// If you would prefer importing from an external source (i.e. Google Fonts), declare them here when using @import
// @import url("//fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700,900");
// $font-secondary: 'Source Sans Pro';

/* open-sans-300 - cyrillic_latin_latin-ext_cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-300.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - cyrillic_latin_latin-ext_cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - cyrillic_latin_latin-ext_cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/open-sans-v13-cyrillic_latin_latin-ext_cyrillic-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'museo_slab500';
    src: url('../fonts/museo/Museo_Slab_500_2-webfont.eot');
    src: url('../fonts/museo/Museo_Slab_500_2-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/museo/Museo_Slab_500_2-webfont.woff2') format('woff2'), url('../fonts/museo/Museo_Slab_500_2-webfont.woff') format('woff'), url('../fonts/museo/Museo_Slab_500_2-webfont.ttf') format('truetype'), url('../fonts/museo/Museo_Slab_500_2-webfont.svg#museo_slab500') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}

@font-face {
  font-family: 'Sailogy';
  src: url('../fonts/sailogy/sailogy.eot');
  src: url('../fonts/sailogy/sailogy.eot?#iefix') format('embedded-opentype'), url('../fonts/sailogy/sailogy.woff') format('woff'), url('../fonts/sailogy/sailogy.ttf') format('truetype'), url('../fonts/sailogy/sailogy.svg#sailogy') format('svg');
}

@charset "UTF-8";

@font-face {
  font-family: "sailogy";
  src:url("../fonts/sailogy/sailogy.eot");
  src:url("../fonts/sailogy/sailogy.eot?#iefix") format("embedded-opentype"),
    url("../fonts/sailogy/sailogy.woff") format("woff"),
    url("../fonts/sailogy/sailogy.ttf") format("truetype"),
    url("../fonts/sailogy/sailogy.svg#sailogy") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sailogy" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sailogy" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-facebook:before {
  content: "a";
}
.icon-google-plus:before {
  content: "c";
}
.icon-next:before {
  content: "b";
}
.icon-prev:before {
  content: "d";
}
.icon-add-boat:before {
  content: "e";
}
.icon-negotiations:before {
  content: "f";
}
.icon-notifications:before {
  content: "g";
}
.icon-support:before {
  content: "h";
}
.icon-boats:before {
  content: "i";
}
.icon-profile:before {
  content: "j";
}
.icon-pricelist:before {
  content: "k";
}
.icon-dash-negotiations:before {
  content: "m";
}
.icon-marinas:before {
  content: "n";
}
.icon-logout:before {
  content: "o";
}
.icon-extras:before {
  content: "q";
}
.icon-discounts:before {
  content: "r";
}
.icon-dashboard:before {
  content: "s";
}
.icon-cancellation:before {
  content: "t";
}
.icon-calendar:before {
  content: "u";
}

@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon.eot?-g1j93j');
  src:url('../fonts/icomoon.eot?#iefix-g1j93j') format('embedded-opentype'), url('../fonts/icomoon.woff?-g1j93j') format('woff'), url('../fonts/icomoon.ttf?-g1j93j') format('truetype'), url('../fonts/icomoon.svg?-g1j93j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-primary: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-secondary: "museo_slab500", Rockwell, Georgia, serif;
$basic_font     : 'Open Sans', Arial, sans-serif;

// Custom Font Mixin for easy declaration of customized fonts on elements

@mixin custom-font($color:$default,$font-family:$font-primary,$font-size:14px,$font-style:normal,$font-weight:400) {
	// Organized alphabetically (color -> weight)
	color: $color;
	font-family: $font-family;
	font-size: $font-size;
	font-style: $font-style;
	font-weight: $font-weight;
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}
