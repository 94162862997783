.twitter-typeahead {
  width: 100%;
}
.twitter-typeahead .tt-query,
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
}
.typeahead {
  background-color: #fff;
}
.typeahead:focus {
  border: 2px solid #0097cf;
}
.tt-query, .tt-input {
  box-shadow: inset 0 1px 1px rgba(black, .075);
}
.tt-hint {
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #999;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(black, .075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.tt-dropdown-menu, .tt-menu {
  text-align: left;
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid rgba(black, .2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(black, .2);
  background-clip: padding-box;
  border-radius: 0;
  right: 0 !important;
}
.tt-suggestion {
  display: block;
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
  color: #5d5d5d;
  cursor: pointer;
  p {
    text-align: left;
    color: #5d5d5d;
    margin: 0;
  }
  &.tt-is-under-cursor,
  &.tt-cursor  {
    color: #000;
    background-color: #f2f2f2; //#0097cf
  }
  &.not-found {
    color: getColor(error, base);
    cursor: default;
  }
}
.top-search-absolute .tt-suggestion p {
  text-align: left;
  color: #5d5d5d;
}
