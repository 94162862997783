.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
    margin-bottom: 0;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query, .tt-input {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  display: block;
  width: 100%;
  height: 34px;
  padding: 8px 13px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #999;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.tt-dropdown-menu {
  /*width: 240px;*/
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
  background-clip: padding-box;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
  color: #5D5D5D;
}

.tt-suggestion p { text-align: left; color: #5D5D5D; margin: 0;}
.top-search-absolute .tt-suggestion p { text-align: left; color: #5D5D5D;}

.tt-suggestion.tt-is-under-cursor, .tt-suggestion:hover {
  &, p {
    color: #fff !important;
    background-color: #0097cf;
  }
}
