.loader {
  @extend .loading;
}
body > .loader, .body > .loader {
  z-index: 10002;
  position: fixed;
  width: 100%;
  height: 100%;
}
.loader div {
  text-align: center;
  background-color: #fffe36;
  color: #484848;
}
.ajax-loading {
  opacity: .7;
}
.loader-container {
  position: absolute;
  z-index: 10000;
  background: rgba(white, .5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body.login {
  .loader-container {
    background: transparent;
    opacity: .5;
  }
}
.loading {
  display: block;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10002;

  text-indent: -9999em;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0);
  color: getColor(info, base);
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -1.5em 0 .1em, 1em -1em 0 0, 1.5em 0 0 -.5em, 1em 1em 0 -.5em, 0 1.5em 0 -.5em, -1em 1em 0 -.5em, -1.5em 0 0 -.5em, -1em -1em 0 0;
  }
  12.5% {
    box-shadow: 0 -1.5em 0 0, 1em -1em 0 .1em, 1.5em 0 0 0, 1em 1em 0 -.5em, 0 1.5em 0 -.5em, -1em 1em 0 -.5em, -1.5em 0 0 -.5em, -1em -1em 0 -.5em;
  }
  25% {
    box-shadow: 0 -1.5em 0 -0.5em, 1em -1em 0 0, 1.5em 0 0 .1em, 1em 1em 0 0, 0 1.5em 0 -.5em, -1em 1em 0 -.5em, -1.5em 0 0 -.5em, -1em -1em 0 -.5em;
  }
  37.5% {
    box-shadow: 0 -1.5em 0 -.5em, 1em -1em 0 -.5em, 1.5em 0 0 0, 1em 1em 0 .1em, 0 1.5em 0 0, -1em 1em 0 -.5em, -1.5em 0 0 -.5em, -1em -1em 0 -.5em;
  }
  50% {
    box-shadow: 0 -1.5em 0 -.5em, 1em -1em 0 -.5em, 1.5em 0 0 -.5em, 1em 1em 0 0, 0 1.5em 0 .1em, -1em 1em 0 0, -1.5em 0 0 -.5em, -1em -1em 0 -.5em;
  }
  62.5% {
    box-shadow: 0 -1.5em 0 -.5em, 1em -1em 0 -.5em, 1.5em 0 0 -.5em, 1em 1em 0 -.5em, 0 1.5em 0 0, -1em 1em 0 .1em, -1.5em 0 0 0, -1em -1em 0 -.5em;
  }
  75% {
    box-shadow: 0 -1.5em 0 -.5em, 1em -1em 0 -.5em, 1.5em 0 0 -.5em, 1em 1em 0 -.5em, 0 1.5em 0 -.5em, -1em 1em 0 0, -1.5em 0 0 .1em, -1em -1em 0 0;
  }
  87.5% {
    box-shadow: 0 -1.5em 0 0, 1em -1em 0 -.5em, 1.5em 0 0 -.5em, 1em 1em 0 -.5em, 0 1.5em 0 -.5em, -1em 1em 0 0, -1.5em 0 0 0, -1em -1em 0 .1em;
  }
}
.endless_loading {
  @extend .loading;
  position: inherit !important;
}
