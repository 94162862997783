// Mobile Styles & Media Queries

@import "colors";

@media (max-width: 768px) {

	.landing section {
		padding: 30px 15px!important;
	}

	.landing .icons article, .landing .about-us article, .landing .partners .logo-container {
		float: left!important;
		width: 33%!important;
		padding: 5px!important;
	}

	.landing h1 {
		font-size: 32px!important;
	}

	.landing .icons h3 {
		font-size: 18px!important;
	}

	.landing .partners a {
		padding: 0 15px!important;
	}

	.landing .about-us h3 {
		font-size: 28px!important;
	}

	.landing .about-us p {
		font-size: 14px!important;
	}

	.landing .about-us h2 {
		margin-bottom: 15px!important;
	}

	.landing .features div.row {
		margin-bottom: 30px!important;
	}

	.landing .how-it-works h2 {
		background: transparent!important;
		padding-bottom: 30px!important;
	}

	.landing .how-it-works h3 {
		text-align: left!important;
	}

	.landing #start {
		clear: both;
	}


	.landing h2 {
		font-size: 28px!important;
	}

	.landing .how-it-works h3 {
		margin-top: 0!important;
	}

	.canvas {
		margin-bottom: 15px!important;
	}

	.landing .how-it-works article{
		margin-left: 6.25% !important;
		border-left: 2px dotted $light-blu !important;
		padding-left: 40px !important;
		position: relative !important;
		&:before {
			content: "";
			background: url(../img/marker01.jpg) no-repeat 0 0;
			position: absolute;
			left: -28px;
			top: 0;
			height: 56px; /*add height of your image here*/ 
			width: 56px; /*add width of your image here*/
			z-index: 1;  
		}
		&:last-child {
			border-left: 0!important;
		}

		&.one:before {
			background: url(../img/marker01.jpg) no-repeat 0 0;
		}

		&.two:before {
			background: url(../img/marker02.jpg) no-repeat 0 0;
		}
		&.three:before {
			background: url(../img/marker03.jpg) no-repeat 0 0;
		}
		&.four:before {
			background: url(../img/marker04.jpg) no-repeat 0 0;
		}

	}
}

@media (max-width: 480px) {
	.landing .icons article, .landing .about-us article, .landing .partners .logo-container {
		float: left!important;
		width: 50%!important;
		padding: 5px!important;
	}
}

@media (max-width: 1023px) {
	#facebook-signup, #google-signup {
		// font-size: 10px!important;
	} 
	.separator{

		.line{
			width: 80px!important;
			min-width: 80px!important;
		}
		small{
			display: inline-block;
			margin: 0 10px;
			position: relative;
			bottom: -3px;

		}

	}
}