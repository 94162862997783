.select2-container {
  width: 100%;
  &.form-control {
    padding: 0;
  }
  .select2-choice {
      height: 33px;
      width: 245px;
  }
}

