// Application Color Profiles
// Description: Set all application-wide color definitions here
// To see examples, see the Color Swatches PNG

// Endpoints
$white: #fff;
$black: #000;

// Greys
$extra-light-grey: #f4f4f4;
$light-grey: #f5f5f5;
$mid-grey: #d7dada;
$dark-grey: #707070;

// Default Color
$default: $dark-grey;

// Flat UI Color Swatches (http://designmodo.github.io/Flat-UI) see the color-swatchs.png
$turquoise: #1abc9c;
$green-sea: #16a085;
$emerald: #2ecc71;
$nephritis: #27ae60;
$peter-river: #3498db;
$belize-hole: #2980b9;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$wet-asphalt: #34495e;
$midnight-blue: #2c3e50;
$sunflower: #f1c40f;
$carrot: #e67e22;
$pumpkin: #d35400;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$concrete: #95a5a6;
$asbestos: #7f8c8d;

// DASHBAR
$blu: #354052;
$sugar-blu: #647d92;
$profile-blu: #323d4e;
$dark-blu: #27303f;
$light-grey: #e9e9e9;
$darker-grey: #e7e7e7;
$green: #10ae4f;


// DASHBOARD CONTENT
$orange: #eb9918;
$light-blu: #2489e7;
$tag-optioned: #f9b705;
$tag-available: #1bcc5e;
$tag-booked: #f90533;
$alert-warning: #fcf5b8;
$alert-warning-text: #72414e;
$tabs-li-background: #e0e0e0;


$branding-red: #dd4406;

// Message Colors
$msg-default: #999;
$msg-success: #5cb85c;
$msg-warning: #f0ad4e;
$msg-danger: #d9534f;
$msg-info: #5bc0de;

//Social Colors
$dailymile: #FFAA25;
$facebook: #3B5998;
$flickr: #f80082;
$github: #F7F7F7;
$google: #d7473d;
$instagram: #37658D;
$linkedin: #2C8CC0;
$pinterest: #c5142b;
$runkeeper: #3193d3;
$skype: #00abee;
$spotify: #7AB800;
$twitter: #3B8ACA;

// Set Site Specific Base, Highlight and Accent Colors Here for easy switching between themes
$base: $dark-grey;
$highlight: $belize-hole;
$accent: $peter-river;
$form-label: rgba(59, 106, 151, 1);
$form-help-text: rgba(80, 160, 176, 1); /*#707070; */
$nav-tabs-active-link-hover-bg: $extra-light-grey;
$nav-tabs-active-link-hover-border-color: $extra-light-grey;

